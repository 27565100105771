.react-datepicker {
  width: 100%;
  border: none;
  font-family: inherit;

  .react-datepicker__month-container {
    width: 100%;

    .react-datepicker__header {
      background: none;
    }

    .react-datepicker__month {
      margin: 0;
    }

    .react-datepicker__current-month {
      font-weight: 600;
    }

    .react-datepicker__week,
    .react-datepicker__day-names {
      display: flex;
    }

    .react-datepicker__day-name,
    .react-datepicker__day {
      flex: 1;
      line-height: 2rem;
      border-radius: 2px;

      &.react-datepicker__day--selected {
        background-color: var(--fluentui-accent-color);
      }

      &.react-datepicker__day--keyboard-selected {
        background-color: transparent;
        color: inherit;
      }
    }
  }
}
