.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .spacer {
    flex: 1;
  }

  .legal {
    padding: 0.5rem;
    text-align: center;

    a {
      margin: 0 0.5rem;
    }
  }
}
